import cx from 'classnames';
import React from 'react';

import ContentLoaderBox from './ContentLoaderBox';
import SkeletonPdf from './SkeletonPdf';
import styles from './styles.css';
import Box from '../../../Components/UI/Box';

const ExpandedNavigation: React.FC = () => (
  <Box className={styles['navigation']}>
    <ContentLoaderBox paddingVertical={3} paddingHorizontal={4} height={54} width={264}>
      <rect x="0" y="0" rx="6" ry="6" width="54" height="54" />
      <rect x="66" y="21" rx="8" ry="8" width="162" height="12" />
    </ContentLoaderBox>
    <ContentLoaderBox className={styles['menu-item']} padding={4} height={18} width={264}>
      <rect x="0" y="3" rx="3" ry="3" width="12" height="12" />
      <rect x="21" y="3" rx="6" ry="6" width="149" height="12" />
    </ContentLoaderBox>
    <ContentLoaderBox
      className={cx(styles['menu-item'], styles['menu-item--selected'])}
      padding={4}
      height={18}
      width={264}
    >
      <rect x="0" y="3" rx="6" ry="6" width="149" height="12" />
      <rect x="227" y="3" rx="6" ry="6" width="37" height="12" />
    </ContentLoaderBox>
    <ContentLoaderBox className={styles['menu-item']} padding={4} height={18} width={264}>
      <rect x="0" y="3" rx="6" ry="6" width="149" height="12" />
      <rect x="227" y="3" rx="6" ry="6" width="37" height="12" />
    </ContentLoaderBox>
    <ContentLoaderBox className={styles['menu-item']} padding={4} height={18} width={264}>
      <rect x="0" y="3" rx="6" ry="6" width="149" height="12" />
      <rect x="227" y="3" rx="6" ry="6" width="37" height="12" />
    </ContentLoaderBox>
    <ContentLoaderBox className={styles['brand']} padding={4} height={18} width={264}>
      <rect x="102" y="3" rx="6" ry="6" width="162" height="12" />
    </ContentLoaderBox>
  </Box>
);

const CollapsedNavigation: React.FC = () => (
  <Box className={cx(styles['navigation'], styles['navigation--collapsed'])}>
    <ContentLoaderBox paddingVertical={3} paddingHorizontal={4} height={58} width={54}>
      <rect x="0" y="0" rx="6" ry="6" width="54" height="54" />
    </ContentLoaderBox>
    <ContentLoaderBox className={styles['brand']} height={27} width={27}>
      <rect x="0" y="0" rx="6" ry="6" width="27" height="27" />
    </ContentLoaderBox>
  </Box>
);

const MobileNavigation: React.FC = () => (
  <Box className={cx(styles['navigation'], styles['navigation--mobile'])}>
    <ContentLoaderBox paddingVertical={3} paddingHorizontal={4} height={54} width={186}>
      <rect x="0" y="0" rx="6" ry="6" width="54" height="54" />
      <rect x="66" y="18" rx="6" ry="6" width="120" height="18" />
    </ContentLoaderBox>
    <ContentLoaderBox className={styles['brand']} paddingHorizontal={5} paddingVertical={3} height={27} width={27}>
      <rect x="0" y="0" rx="6" ry="6" width="27" height="27" />
    </ContentLoaderBox>
  </Box>
);

interface LoadingSkeletonProps {
  style?: 'expanded' | 'collapsed' | 'mobile';
}

const LoadingSkeleton: React.FC<LoadingSkeletonProps> = ({ style = 'expanded' }) => {
  return (
    <Box className={cx(styles['loader'], { [styles['loader--mobile']]: style === 'mobile' })}>
      {style === 'collapsed' && <CollapsedNavigation />}
      {style === 'expanded' && <ExpandedNavigation />}
      {style === 'mobile' && <MobileNavigation />}
      <Box className={styles['main']}>
        <Box className={cx(styles['header'], { [styles['header--mobile']]: style === 'mobile' })}>
          <ContentLoaderBox padding={4} height={24} width={314}>
            <rect x="0" y="0" rx="16" ry="16" width="314" height="24" />
          </ContentLoaderBox>
          <ContentLoaderBox paddingHorizontal={4} paddingVertical={3} height={36} width={123} viewBox="0 0 123 36">
            <rect x="0" y="0" rx="8" ry="8" width="123" height="36" />
          </ContentLoaderBox>
        </Box>
        <SkeletonPdf small={style === 'mobile'} marginTop={5} />
      </Box>
    </Box>
  );
};

export default LoadingSkeleton;
