import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import ErrorBoundary from './Components/Context/ErrorBoundary';
import { useIsSmall } from './Components/Context/Hooks/mediaQueries';
import Box from './Components/UI/Box';
import ErrorFallback from './Components/UI/ErrorFallback';
import ExternalRedirect from './Pages/ExternalRedirect';
import LoadingSkeleton from './Pages/Home/LoadingSkeleton/LoadingSkeleton';
import Terms from './Pages/Terms';
import { DecodedUrlData } from './Utils/jwt';
import lazyLoad from './Utils/lazyLoad';
import theme from './styles.css';

const Home = lazyLoad(() => import('./Pages/Home'));
const Sandbox = lazyLoad(() => import('./Pages/Sandbox'));

export interface AppProps {
  data: DecodedUrlData;
}

const App: React.FC<AppProps> = ({ data }) => {
  const isSmall = useIsSmall();
  const suspendedHome = (
    <Suspense fallback={<LoadingSkeleton style={isSmall ? 'mobile' : 'collapsed'} />}>
      <Home jwtData={data} />
    </Suspense>
  );

  return (
    <Box display="flex" className={theme['app']}>
      <ErrorBoundary fallback={<ErrorFallback />}>
        <Router>
          <Routes>
            {(__DEV__ || __TEST__) && (
              <Route
                path="sandbox"
                element={
                  <Suspense fallback={null}>
                    <Sandbox />
                  </Suspense>
                }
              />
            )}

            <Route path="terms/*" element={<Terms />} />

            <Route path=":token" element={suspendedHome} />
            <Route path=":token/:id" element={suspendedHome} />
            <Route path=":token/:id/:detailId" element={suspendedHome} />

            <Route path="*" element={<ExternalRedirect to="https://teamleader.eu/" />} />
          </Routes>
        </Router>
      </ErrorBoundary>
    </Box>
  );
};

export default App;
