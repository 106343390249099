import cx from 'classnames';
import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';

import styles from './styles.css';
import Box, { BoxProps } from '../Box';
import Spinner from '../Spinner';

export interface ButtonProps extends BoxProps, Omit<React.HTMLProps<HTMLButtonElement>, 'ref'> {
  level?: 'primary' | 'secondary' | 'destructive';
  processing?: boolean;
}

const Button = forwardRef<HTMLElement, ButtonProps>(
  (
    {
      element = 'button',
      level = 'secondary',
      className,
      children,
      processing,
      disabled,
      onMouseLeave,
      onMouseUp,
      ...rest
    },
    ref,
  ) => {
    const buttonRef = useRef<HTMLButtonElement>();
    useImperativeHandle(ref, () => buttonRef.current!);

    const classNames = cx(
      styles['button'],
      styles[`level-${level}`],
      { [styles['is-processing']]: processing },
      className,
    );

    const handleMouseLeave = useCallback(
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        buttonRef.current?.blur();
        onMouseLeave && onMouseLeave(event);
      },
      [onMouseLeave],
    );

    const handleMouseUp = useCallback(
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        buttonRef.current?.blur();
        onMouseUp && onMouseUp(event);
      },
      [onMouseUp],
    );

    return (
      <Box
        ref={buttonRef}
        element={element}
        className={classNames}
        disabled={disabled || processing}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        {...rest}
      >
        {children}
        {processing && <Spinner className={styles['spinner']} inverse={level === 'primary'} />}
      </Box>
    );
  },
);

export default Button as React.ComponentType<ButtonProps>;
