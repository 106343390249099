import cx from 'classnames';
import React, { forwardRef } from 'react';
import { LinkProps as RouterLinkProps } from 'react-router-dom';

import styles from './styles.css';
import Box, { BoxProps } from '../Box';

export enum SIZES {
  SMALL,
  MEDIUM,
}

interface LinkProps extends BoxProps, Partial<RouterLinkProps> {
  disabled?: boolean;
  size?: SIZES;
  muted?: boolean;
}

const Link = forwardRef<HTMLElement, LinkProps>(
  ({ element, className, onClick, href, to, disabled, size = SIZES.MEDIUM, muted, ...rest }, ref) => {
    const el = element || (href ? 'a' : 'button');
    const isDisabled = (!href && !onClick && !to) || disabled;

    return (
      <Box
        ref={ref}
        element={el}
        className={cx(className, styles['link'], {
          [styles['link--disabled']]: isDisabled,
          [styles['link--small']]: size === SIZES.SMALL,
          [styles['link--muted']]: muted,
        })}
        href={href}
        onClick={!disabled ? onClick : undefined}
        to={to}
        disabled={el === 'button' ? isDisabled : undefined}
        {...rest}
      />
    );
  },
);

export default Link as React.ComponentType<LinkProps>;
