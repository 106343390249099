import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Dutch from './Languages/Dutch';
import English from './Languages/English';
import French from './Languages/French';
import German from './Languages/German';
import Italian from './Languages/Italian';
import Spanish from './Languages/Spanish';
import styles from './styles.css';
import Box from '../../Components/UI/Box';
import Logo from '../../Svg/153x32_logo.svg';

const Terms: React.FC = () => {
  return (
    <Box className={styles['terms']}>
      <Box className={styles['header']}>
        <Logo className={styles['title']} />
      </Box>
      <Box className={styles['body']}>
        <Routes>
          <Route path="nl" element={<Dutch />} />

          <Route path="fr" element={<French />} />

          <Route path="de" element={<German />} />

          <Route path="it" element={<Italian />} />

          <Route path="es" element={<Spanish />} />

          <Route path="*" element={<English />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default Terms;
