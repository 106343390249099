import React from 'react';

import ContentLoaderBox from './ContentLoaderBox';
import styles from './styles.css';
import Box, { BoxProps } from '../../../Components/UI/Box';

interface LineProps extends BoxProps {
  width: number;
  lineWidth?: number;
}

const Line: React.FC<LineProps> = ({ width, ...rest }) => (
  <ContentLoaderBox width={width} height={18} padding={4} {...rest}>
    <rect x="0" y="5" rx="6" ry="6" width={width} height="12" />
  </ContentLoaderBox>
);

interface SkeletonPdfProps extends BoxProps {
  small?: boolean;
}

const SkeletonPdf: React.FC<SkeletonPdfProps> = ({ small, ...rest }) => {
  const multiplier = small ? 0.25 : 1;
  return (
    <Box className={styles['page']} marginHorizontal={5} marginBottom={5} flex={1} {...rest}>
      <Box className={styles['inner-page']}>
        <Box display="flex" justifyContent="space-between">
          <ContentLoaderBox height={170} width={200}>
            <rect x="0" y="0" rx="6" ry="6" width="60" height="60" />
            <rect x="0" y="84" rx="6" ry="6" width="200" height="12" />
            <rect x="0" y="108" rx="6" ry="6" width="153" height="12" />
            <rect x="0" y="132" rx="6" ry="6" width="104" height="12" />
            <rect x="0" y="156" rx="6" ry="6" width="70" height="12" />
          </ContentLoaderBox>
          <ContentLoaderBox height={60} width={141}>
            <rect x="0" y="0" rx="6" ry="6" width="141" height="12" />
            <rect x="35" y="24" rx="6" ry="6" width="106" height="12" />
            <rect x="86" y="48" rx="6" ry="6" width="55" height="12" />
          </ContentLoaderBox>
        </Box>
        <Box element="table" marginTop={7} className={styles['table']}>
          <thead>
            <tr>
              <th>
                <Line width={186 * multiplier} />
              </th>
              <th>
                <Line width={36} />
              </th>
              <th>
                <Line width={36} />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Line width={270 * multiplier} />
              </td>
              <td>
                <Line width={36} />
              </td>
              <td>
                <Line width={36} />
              </td>
            </tr>
            <tr>
              <td>
                <Line width={120 * multiplier} />
              </td>
              <td>
                <Line width={36} />
              </td>
              <td>
                <Line width={36} />
              </td>
            </tr>
            <tr>
              <td>
                <Line width={180 * multiplier} />
              </td>
              <td>
                <Line width={36} />
              </td>
              <td>
                <Line width={36} />
              </td>
            </tr>
            <tr>
              <td>
                <Line width={60 * multiplier} />
              </td>
              <td>
                <Line width={36} />
              </td>
              <td>
                <Line width={36} />
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={3}>
                <Line width={120} />
              </td>
            </tr>
          </tfoot>
        </Box>
      </Box>
    </Box>
  );
};

export default SkeletonPdf;
